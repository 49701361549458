import axios from '../../util/axios';
import router from '../../router';
import api from '../../util/api';
import { Message } from 'element-ui';
import { resetVuex } from '@/util/util';
const default_state = {
  menuLeft: [],
  userInfo: {},
  shopInfo: {},
  relationMenu: [],
  loadingFlag: false,
}
const state = {
  menuLeft: [
    {
      path: '/goods',
      meta: {
        icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/homeMenu/goods.png',
        title: '商品管理',
        name: '商品',
        rules: 1,
      },
      children: [{
        path: '/goods/goodsList',
        name: '商品列表',
        meta: {
          name: '商品列表',
          rules: 1,
        }
      },{
        path: '/goods/addGoods',
        name: '添加商品',
        meta: {
          name: '添加商品',
          rules: 1,
        }
      },{
        path: '/goods/goodsEvaluate',
        name: '商品评价',
        meta: {
          name: '商品评价',
          rules: 1,
        }
      }]
    },{
      path: '/service',
      meta: {
        icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/homeMenu/goods.png',
        title: '服务管理',
        name: '服务',
        rules: 1,
      },
      children:[{
        path: '/service/serviceList',
        name: '服务列表',
        meta: {
          name: '服务列表',
          rules: 1,
        }
      },{
        path: '/service/serviceAdd',
        name: '添加服务',
        meta: {
          name: '添加服务',
          rules: 1,
        }
      },{
        path: '/service/serviceEvaluate',
        name: '服务评价',
        meta: {
          name: '服务评价',
          rules: 1,
        }
      }]
    },
    {
      path: '/order',
      meta: {
        icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/homeMenu/goods.png',
        title: '订单管理',
        name: '订单',
        rules: 1,
      },
      children:[{
        path: '/order/orderList',
        name: '商品订单',
        meta: {
          name: '商品订单',
          rules: 1,
        }
      },{
        path: '/order/serviceOrder',
        name: '服务订单',
        meta: {
          name: '服务订单',
          rules: 1,
        }
      }]
    },
    {
      path: '/shop',
      meta: {
        icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/homeMenu/goods.png',
        title: '店铺管理',
        name: '店铺',
        rules: 1,
      },
      children:[{
        path: '/shop/consignmentGoods',
        name: '代销商品',
        meta: {
          name: '代销商品',
          rules: 1,
        }
      },{
        path: '/shop/consignmentService',
        name: '代销服务',
        meta: {
          name: '代销服务',
          rules: 1,
        }
      }]
    },
    {
      path: '/extension',
      meta: {
        icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/homeMenu/goods.png',
        title: '推广管理',
        name: '推广',
        rules: 1,
      },
      children:[{
        path: '/extension/currentprice',
        name: '时价管理',
        meta: {
          name: '时价管理',
          rules: 1,
        }
      },{
        path: '/extension/advertisement',
        name: '广告管理',
        meta: {
          name: '广告管理',
          rules: 1,
        }
      }]
    }
  ],
  userInfo: {},
  shopInfo: {},
  relationMenu: [],
  loadingFlag: false,
};

const mutations = {
  resetState(state) {
    let data = Object.assign({}, default_state);
    for (let i in data) {
      state[i] = data[i];
    }
  },
  setRoutes(state, data) {
    state.menuLeft = data;
  },
  setRelationMenu(state, data) {
    state.relationMenu = data;
  },
  getUserInfo(state, data) {
    state.userInfo = data || {};
  },
  getShopInfo(state, data) {
    state.shopInfo = data || {};
  },
  changeLoadingFlag(state, data) {
    state.loadingFlag = data;
  },
};

const actions = {
  outLogin() {
    axios.post(api.user.logout).then((res) => {
      if (res.code == 0) {
        if (res.code == 0) {
          Message({
            message: '退出登录成功',
            type: 'success',
          });
          resetVuex();
          sessionStorage.removeItem('token')
          router.push('/login');
        }
      } else {
        Message.error(res.msg);
      }
    });
  },
  getUserInfo({ commit }) {
    axios.post(api.user.userInfo).then((res) => {
      if (res.code == 0) {
        commit('getUserInfo', res.result);
      } else {
        Message.error(res.msg);
      }
    });
  },
  getShopInfo({ commit }) {
    axios.post(api.user.shopInfo).then((res) => {
      if (res.code == 0) {
        commit('getShopInfo', res.result);
      } else {
        Message.error(res.msg);
      }
    });
  },
  get_menu({ commit }, data = {}) {
    axios.post(api.user.getMenu).then((res) => {
      if (res.code == 0) {
        let arr = [];
        let list = res.result;
        for (let i in list) {
          let children = list[i].childs || [];
          let obj = {
            path: list[i].auth_key,
            meta: {
              icon: list[i].auth_icon,
              title: list[i].auth_meta,
              name: list[i].auth_name,
              rules: 1,
            },
            children: [],
          };
          for (let y in children) {
            obj.children.push({
              path: children[y].auth_key,
              name: children[y].auth_name,
              meta: {
                rules: 1,
                name: children[y].auth_name,
              },
            });
          }
          arr.push(obj);
        }
        commit('setRoutes', arr);
        if (arr.length) data.success();
      } else {
        Message.error(res.msg);
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
