<template>
  <div id="app">
    <router-view></router-view>
     <Loading v-if="loading"></Loading>
  </div>
</template>
<script>
import Loading from './components/loading.vue'
export default {
  components: {
    Loading
  },
  data() {
    return {}
  },
  computed: {
    loading() {
      return this.$store.state.user.loadingFlag
    }
  },
  mounted() {}
}
</script>

<style lang="less">
html,
body,
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background: #ebf0f3;
  width: 100%;
  height: 100%;
}
img {
  vertical-align: top;
}
.el-button {
  font-size: 12px !important;
}
.indexBox11 {
  img {
    width: 100% !important;
    height: auto !important;
  }
}
</style>
