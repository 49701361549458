var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout"},[_c('div',{staticClass:"layout-menu"},[_c('div',{staticClass:"logo"},[_c('el-image',{attrs:{"src":_vm.userInfo.staff_avatar || require('@/assets/user.png')}})],1),_c('el-menu',{staticClass:"el-menu-vertical-demo",attrs:{"background-color":"#010E27","text-color":"#fff","active-text-color":"#fff"},on:{"select":_vm.selectMenu}},_vm._l((_vm.routers),function(item,index){return _c('el-menu-item',{key:index,attrs:{"index":index}},[_c('div',{class:['item', _vm.activeIndex == index ? 'el-menu-item-active' : '']},[_c('img',{staticStyle:{"margin-right":"8px"},attrs:{"src":item.meta.icon,"alt":""}}),_c('span',[_vm._v(_vm._s(item.meta.name))])])])}),1)],1),(_vm.routers.length)?_c('div',{ref:"child_menu",staticClass:"child-menu",style:({ left: _vm.isOpen ? '120px' : '-35px' })},[_c('div',{staticClass:"child-menu-title"},[_vm._v(" "+_vm._s(_vm.routers[_vm.activeIndex] && _vm.routers[_vm.activeIndex].meta.title)+" ")]),_vm._l((_vm.routers[_vm.activeIndex] && _vm.routers[_vm.activeIndex].children),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"title",on:{"click":function($event){return _vm.selectChildMenu(item, index)}}},[_c('span',{style:({
            background: index == _vm.activeIndex1 && !item.meta.isShowThirdMemu ? '#3370ff' : '#fff',
          })}),_c('a',{style:({ color: index == _vm.activeIndex1 && !item.meta.isShowThirdMemu ? '#3370ff' : '' })},[_vm._v(_vm._s(item.meta.name))]),_c('i',{directives:[{name:"show",rawName:"v-show",value:(item.meta.isShowThirdMemu),expression:"item.meta.isShowThirdMemu"}],staticClass:"el-icon-arrow-right",style:(item.meta.isOpenThirdMenu
              ? {
                  transform: "rotateZ(90deg)",
                }
              : {
                  transform: "rotateZ(0deg)",
                })})]),_c('div',{ref:"item_child_box",refInFor:true,staticClass:"item-child-box"},_vm._l((item.children),function(childItem,childIndex){return _c('div',{key:childIndex,staticClass:"item-child",attrs:{"id":"item-child"},on:{"click":function($event){return _vm.selectThirdMemu(childItem, childIndex, index)}}},[_c('span',{style:({ color: childItem.meta.selected ? '#3370ff' : '' })},[_vm._v(_vm._s(childItem.meta.name))])])}),0)])})],2):_vm._e(),_c('div',{staticClass:"menu-content"},[_c('div',{ref:"seize",staticClass:"seize",style:({ width: _vm.isOpen ? '135px' : '0' })}),_c('div',{staticClass:"view-content"},[_c('div',{staticClass:"view-title"},[(!_vm.showEnd_time)?_c('div',[_vm._v(" "+_vm._s(_vm.shwoPageName)+" ")]):_c('div'),_c('div',{staticClass:"userInfo"},[_c('div',{staticClass:"tips"},[_c('el-badge',{attrs:{"value":_vm.msgCount,"hidden":_vm.msgCount == 0}},[_c('i',{staticClass:"iconfont icon--_tishi"})])],1),_c('el-dropdown',{staticClass:"userName",on:{"command":_vm.handleCommand}},[_c('span',{staticClass:"el-dropdown-link"},[_c('i',{staticClass:"iconfont icon-guanliyuan"}),_vm._v(" "+_vm._s(_vm.userInfo.staff_account || 'admin')+" "),_c('i',{staticClass:"el-icon-caret-bottom"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":"outLogin"}},[_vm._v("退出登录")])],1)],1)],1)]),_c('div',{staticClass:"router-view"},[_c('router-view',{key:_vm.$route.fullPath,ref:"rightBot",on:{"msgCountMethod":_vm.msgCountMethod}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.is_form),expression:"!is_form"}],staticClass:"t_logo"},[_c('el-image',{attrs:{"src":require('@/assets/t_logo.png')}})],1)],1)])]),_c('audio',{ref:"audio",attrs:{"src":require("../assets/audio/neworderTZ.mp3")}})])}
var staticRenderFns = []

export { render, staticRenderFns }