import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import store from '../store';
import routerSurfaces from './routerSurface.js';
import _ from "lodash";
Vue.use(VueRouter);
const routes = [
  {
    path: '/print/:id',
    name: 'print',
    component: () => import('@/views/Print'),
    meta: {
      rules: 0,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      rules: 0,
    },
  },
];

const createRouter = () =>
  new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
  });
const router = createRouter();
function setAddRouter() {
  const newRouter = createRouter();
  let menuLeft = store.getters.menuLeft;
  let routerSurface = _.cloneDeep(routerSurfaces)
  let arr = [];
  let newArray = [];
  let relationMenu = [];
  let relationMenuName = [];
  let sortArr = [];
  //寻找匹配一级菜单
  routerSurface.map((item) => {
    menuLeft.map((items) => {
      if (item.path == items.path) {
        item.meta.name = items.meta.name;
        arr.push(item);
      }
    });
    //处理首页显示
    menuLeft.map((items) => {
      if (item.path == '/' && items.path == 'index') {
        item.children[0].meta.key = 1;
        items.path = '/';
        arr.unshift(item);
      }
    });
  });
  //获取不用显示在菜单的路由
  arr.map((item) => {
    item.children.map((items) => {
      if (items.meta.rules == 0) {
        relationMenu.push(items);
      }
    });
  });
  //寻找匹配二级菜单 key为1 则有二级菜单
  menuLeft.map((item1) => {
    item1.children.map((item2) => {
      arr.map((item3, index) => {
        item3.children.map((item4) => {
          if (item2.path == item4.path) {
            item4.meta.key = 1;
            item4.meta.name = item2.meta.name;
          }
        });
      });
    });
  });
  //过滤掉不是二级菜单
  arr.forEach((now, index) => {
    newArray[index] = now;
    newArray[index].children = now.children.filter((item) => item.meta.key);
  });
  //添加不显示菜单的路由
  for (let i in newArray) {
    let children = newArray[i].children;
    for (let y in children) {
      for (let k in relationMenu) {
        if (children[y].name == relationMenu[k].meta.relation) {
          children.push(relationMenu[k]);
          relationMenuName.push(relationMenu[k].name);
        }
      }
      for (let k in children[y].children) {
        relationMenuName.push(children[y].children[k].name);
      }
    }
  }
  //根据接口返回的菜单顺序进行路由排序
  for (let i in menuLeft) {
    sortArr[i] = newArray.find((item) => item.path == menuLeft[i].path);
    if (sortArr[i].children.length) {
      for (let y in menuLeft[i].children) {
        for (let k in sortArr[i].children) {
          if (menuLeft[i].children[y].path == sortArr[i].children[k].path) {
            let temp = sortArr[i].children[k];
            sortArr[i].children[k] = sortArr[i].children[y];
            sortArr[i].children[y] = temp;
          }
        }
      }
    }
  }
  
  //设置菜单
  store.commit('user/setRoutes', _.cloneDeep(newArray));
  //设置不显示的菜单
  store.commit('user/setRelationMenu', relationMenuName);
  
  router.matcher = newRouter.matcher;
  router.addRoutes(newArray);
}
setAddRouter();
router.beforeEach((to, from, next) => {
  if (to.path == '/login') {
    next();
  } else {
    let token = sessionStorage.getItem('token');
    if (!token) {
      next('/login');
    } else {
      let menuLeft = store.getters.menuLeft;
      if (menuLeft.length) {
        if (to.matched.length === 0) {
          next('/404');
        } else {
          next();
        }
      } else {
        next();
        store.dispatch('user/get_menu', {
          success() {
            setAddRouter();
            menuLeft = store.getters.menuLeft;
            let path = from.path == '/login' ? menuLeft[0].path : to.path;
            next({
              path: path,
              query: to.query,
            });
          },
        });
      }
    }
  }
});
export default router;