const api = {
  user: {
    login: '/Login/login',
    logout: '/Login/logout',
    userInfo: '/Login/self',
    addFiles: '/Upload/addFiles',
    imgList: '/image/list',
    shopInfo: '/Store/info', //店铺信息
    getMenu: '/Auths/getMenu', //获取菜单
    getAuths: '/Auths/getAuths', //获取全部菜单
  },
  goods: {
    goodsClass: '/Goods/classifyList',
    addGoods: '/Goods/add',
    addType: '/StoreGoodsClassify/add',
    list: '/Goods/list',
    editInfo: '/Goods/editInfo', //编辑商品信息
    delType: '/StoreGoodsClassify/del',
    delGoods: '/Goods/del',
    editGoods: '/Goods/edit',
    editType: '/StoreGoodsClassify/edit',
    goodsInfo: '/Goods/info',
    shiftGoods: '/Goods/shiftGoods',
    commentList: '/GoodsComment/list', //评价列表
    reply: '/GoodsComment/reply', //回复
    setStatus: '/GoodsComment/setStatus', //评价显示隐藏
    delComment: '/GoodsComment/del', //删除评价
    setPutaway: '/Goods/setPutaway', //设置上架\下架
    goodsStatus: '/Goods/memberGoodsStatus', //商品状态
    isSelect: '/Goods/isSelect', //是否有默认运费模块
    export: '/Goods/export', //导出商品
    import: '/Goods/import', //导入商品
    copyGoods: '/Goods/copyGoods', //复制商品
    goodsAudit: '/Goods/goodsAudit',//发布审核
    goodsAuditLog: '/Goods/goodsAuditLog',//审核记录
    wxCode: '/Goods/wxCode',
    setFreight: '/Goods/setFreight',
    setClassify: '/Goods/setClassify',
    exportGoodsClass: '/StoreGoodsClassify/export',//导出商品分类
  },
  service: {
    ServiceClassify: '/Service/classifyList',
    addServiceClassify: '/ServiceClassify/add',
    editServiceClassify: '/ServiceClassify/edit',
    delServiceClassify: '/ServiceClassify/del',
    addService: '/Service/add', // 添加服务
    serviceList: '/Service/list', // 查询服务列表
    editStatus: '/Service/setPutaway', // 更改服务上架状态
    editInfo: '/Service/editInfo', // 更改服务信息
    delService: '/Service/del', // 删除服务
    serviceInfo: '/Service/info', // 获取服务详情
    editService: '/Service/edit', // 编辑服务
    threeLevelList: '/ServiceClassify/threeLevelList', //获取三级分类
    serviceComment:'/ServiceComment/list',
    serviceCommentReply:'/ServiceComment/reply',
    serviceCommentStatus:'/ServiceComment/setStatus'
  },
  order: {
    list: '/order/list', //获取订单列表
    info: '/order/info', //获取订单详情
    setSend: '/order/setSend', //订单发货接口
    num: '/order/stateNum', //订单数量
    express: '/express/list', //物流信息
    logisticsQuery: '/Order/logisticsQuery', //物流信息查询
    setSystemMessage: '/Order/setSystemMessage', //添加系统备注信息
    changePrice: '/Order/changePrice', //修改订单价格
    cancel: '/Order/cancel', //取消未付款订单
    cancelList: '/Orderrefundreason/list', //退款原因
    printOrder: '/Order/printOrder', //打印订单
    handleRefund: '/Orderrefund/handle', //处理退款
    salesList: '/Orderrefund/list', //售后列表
    refundInfo: '/Orderrefund/info', //退款详情
    receive: '/Orderrefund/receive', //商家收货
    refund: '/Orderrefund/refund', //原路退回到钱包
    confirmReceipt: '/order/confirmReceipt', //订单确认收货
    refundNum: '/Orderrefund/stateNum', //退款订单数量
  },
  shop:{
    consignmentGoods:'/ConsignmentGoods/list',
    consignmentService:'/ConsignmentService/list',
    soldGoodsOut:'/ConsignmentGoods/consignmentGoodsDel',
    soldServiceOut:'/ConsignmentService/consignmentServiceDel'
  },
  Ad:{
    classify_list: '/Ad/ClassifyList',
    list: '/Ad/list',
    add: '/Ad/add',
    info: '/Ad/info',
    edit: '/Ad/edit',
    del: '/Ad/del',
    setPutaway: '/Ad/setPutaway',
    setSort: '/Ad/setSort',
    AdSet: '/AdSet/info',
    editExplains: '/AdSet/editExplains',
    editPrice: '/AdSet/editPrice',
    order: '/AdOrder/list',
    orderInfo: '/AdOrder/info',
    orderAudit: '/AdOrder/audit',
    orderStatus: '/AdOrder/getStatus',
    showTime: '/AdOrder/getShowTime',
  },
  freight: {
    list: '/Freight/list', //运费列表
  },
  store: {
    edit: '/Store/edit',
    storeUsersList: '/StoreUsers/list', //店员列表
    addStoreUsers: '/StoreUsers/add', //添加店员
    editStoreUsers: '/StoreUsers/edit', //编辑店员
    delStoreUsers: '/StoreUsers/del', //删除店员
    rolesList: '/StoreRoles/list', //门店角色列表
    addRoles: '/StoreRoles/add', //添加门店角色
    editRoles: '/StoreRoles/edit', //编辑门店角色
    delRoles: '/StoreRoles/del', //删除门店角色
  },
  set: {
    editVideo: '/Upload/editVideo', // 添加视频信息
    freightAdd: '/Freight/add', //添加运费
    freightList: '/Freight/list', //运费列表
    freightDel: '/Freight/del', //删除运费
    freightEdit: '/Freight/edit', //编辑运费
    setDefaultFreight: '/Freight/setDefault', //设置默认运费
    Settinginfo: '/ShopSetting/info', // 获取设置信息
    Settingedit: '/ShopSetting/edit', // 修改设置
    Settingadd: '/ShopSetting/add', // 添加设置
    area: '/area/list', //省市区
    ShopSms: '/ShopSms/getData',
  },
  //推广
  push: {
    couponList: '/Coupon/list', //优惠券列表
    editStatus: '/Coupon/editStatus',
    currentprice: {
      setInfo: '/CurrentPrices/currentPricesClassify',
      editSubscriptionPrice: '/CurrentPricesSet/editSubscriptionPrice',
      editSubscriptionDiscount: '/CurrentPricesSet/editSubscriptionDiscount',
      editExplains: '/CurrentPricesSet/editExplains',
      list: '/CurrentPrices/list',
      classify: '/CurrentPricesSet/currentPricesClassify',
      add:'/CurrentPrices/add',
      del:'/CurrentPrices/del',
      auditList:'/CurrentPrices/auditList',
      audit:'/CurrentPricesAudit/audit',
      getStatus:'/CurrentPricesOrder/getStatus',
      orderList:'/CurrentPrices/orderList'
    },
  },
  // 首页
  homeIndex: {
    rsealTime: '/Index/top', // 实时概况
    msgNotification: '/Index/message', // 消息通知
    shortcut: '/Index/shortcut', // 快捷入口
    echarsData: '/Index/data', // 数据展示
    goodsRanking: '/Index/goodsTop', //商品排行
    readMsg: '/Index/readMessage', // 阅读消息通知
  },
  // 素材中心
  sourceCenter: {
    ImagesGroupList: '/ImagesGroup/list', //所有图片分组
    ImagesGroupSort: '/ImagesGroup/sort', //图片分组排序
    ImagesGroupAdd: '/ImagesGroup/add', // 添加图片分组
    ImagesGroupEdit: '/ImagesGroup/edit', // 修改图片分组
    ImagesGroupDel: '/ImagesGroup/del', // 删除图片分组
    ImageMove: '/Image/move', // 移动图片分组
    ImageDel: '/Image/dels', // 删除图片
    UpdateName: '/Image/rename', //修改图片名字
    JwtList: '/JwtImages/JwtList', // 站点外获取图片分类
    rename: '/ImagesGroup/rename', // 修改文件夹名字
    moveGroup: '/ImagesGroup/move' // 移动文件夹
  },
  //财务
  finance: {
    accountInfo: '/Store/accountInfo', //账户信息
    editAccount: '/Store/editAccount', //编辑账户信息
    withdrawnList: '/Withdraw/withdrawnList', //提现订单列表
    withdraw: '/Withdraw/withdraw', //提现
    status: '/Withdraw/getStatus', //提现记录状态
    withdrawLog: '/Withdraw/withdrawLog', //提现记录
    getWxOpenid: '/Store/getWxOpenid',//微信用户
    withdrawLogExport: '/Withdraw/withdrawLogExport',//导出提现记录
  },
  //收银台
  cashier: {
    CashierUsersList: '/CashierUsers/list', //账号列表
    CashierUsersAdd: '/CashierUsers/add', //添加账号
    CashierUsersEdit: '/CashierUsers/edit', //编辑账号
    CashierUsersDel: '/CashierUsers/del', //删除账号
    CashierOrder: '/Order/cashierOrder', //收银订单列表
    CashierOrderStatus: '/Order/cashierOrderStatus', //收银订单状态列表
    CashierPrinterConfig: '/PrinterConfig/info', //获取打印机配置信息
    CashierPrinterEdit: '/PrinterConfig/edit', //获取打印机配置信息
  },
  en:{
    storeInfo:'/En/Store/info',
    storeClassify:'/En/Store/classifyList',
    editStoreInfo:'/En/Store/edit',
    recommendGoodsInfo:'/En/StoreRecommendGoods/info',
    editRecommendGoods:'/En/StoreRecommendGoods/edit',
    list:'/En/StoreRecommendGoods/list',
    addVideoInfo:'/En/ShortVideo/add',
    editVideoInfo:'/En/ShortVideo/edit',
    delVideoInfo:'/En/ShortVideo/del',
    getVideoInfo:'/En/ShortVideo/info',
    getVideoClassifyList:'/En/ShortVideo/classifyList',
    videoList:'/En/ShortVideo/list',
    quickEditVideoInfo:'/En/ShortVideo/editInfo'
  }
};

export default api;
