const default_state = {
  page: 1,
  rows: 10,
  is_record: !1,
  is_update: 0,
  member_discount: 0,
};
const state = {
  page:1,
  rows:10,
  is_record: !1,
  is_update: 0,
  member_discount: 0,
};
const mutations = {
  resetState(state) {
    let data = Object.assign({}, default_state);
    for(let i in data){
      state[i] = data[i]
    }
  },
};

const actions = {

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
