import Home from '../views/Home.vue';
import Index from '../views/index/Index.vue';
let routerSurface = [
  {
    path: '/',
    name: '',
    redirect: 'index',
    component: Home,
    meta: {
      icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/homeMenu/index.png',
      name: '首页',
      rules: 1,
    },
    children: [
      {
        path: 'index',
        component: Index,
        name: 'index',
        meta: {
          name: '首页',
          rules: 1,
          form: 1,
        },
      },
    ],
  },
  {
    path: '/goods',
    name: 'goods',
    component: Home,
    redirect: '/goods/goodsList',
    meta: {
      icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/homeMenu/goods.png',
      name: '商品',
      title: '商品管理',
      rules: 1,
    },
    children: [
      {
        path: '/goods/goodsList',
        component: () => import('@/views/goods/GoodsList'),
        name: 'goodsList',
        meta: {
          name: '商品列表',
          rules: 1,
        },
      },
      {
        path: '/goods/addGoods',
        component: () => import('@/views/goods/AddGoods'),
        name: 'addGoods',
        meta: {
          name: '添加商品',
          rules: 1,
          form: 1,
        },
      },
      {
        path: '/goods/editGoods',
        component: () => import('@/views/goods/AddGoods'),
        name: 'editGoods',
        meta: {
          name: '编辑商品',
          relation: 'goodsList',
          rules: 0,
          form: 1,
        },
      },
      {
        path: '/goods/editGoodsType/:id',
        component: () => import('@/views/goods/AddType'),
        name: 'editGoodsType',
        meta: {
          name: '编辑分类',
          relation: 'goodsClassify',
          rules: 0,
        },
      },
      {
        path: '/goods/goodsClassify',
        component: () => import('@/views/goods/GoodsType'),
        name: 'goodsClassify',
        meta: {
          name: '商品分类',
          rules: 1,
        },
      },
      {
        path: '/goods/addGoodsClassify',
        component: () => import('@/views/goods/AddType'),
        name: 'addGoodsClassify',
        meta: {
          name: '添加分类',
          rules: 1,
          form: 1,
        },
      },
      {
        path: '/goods/goodsEvaluate',
        component: () => import('@/views/goods/GoodsEvaluate'),
        name: 'goodsEvaluate',
        meta: {
          name: '商品评价',
          rules: 1,
        },
      },
    ],
  },
  {
    path: '/service',
    name: 'service',
    component: Home,
    redirect: '/service/serviceList',
    meta: {
      icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/homeMenu/goods.png',
      name: '服务',
      title: '服务管理',
      rules: 1,
    },
    children: [{
      path: '/service/serviceList',
      component: () => import('@/views/service/serviceList'),
      name: 'serviceList',
      meta: {
        name: '服务列表',
        rules: 1,
      },
    }, {
      path: '/service/serviceAdd',
      component: () => import('@/views/service/serviceAdd'),
      name: 'serviceAdd',
      meta: {
        name: '添加服务',
        rules: 1,
      },
    },{
      path: '/service/serviceEdit',
      component: () => import('@/views/service/serviceAdd'),
      name: 'serviceEdit',
      meta: {
        name: '编辑服务',
        relation: 'serviceList',
        rules: 0
      },
    },  {
      path: '/service/serviceEvaluate',
      component: () => import('@/views/service/serviceEvaluate'),
      name: 'serviceEvaluate',
      meta: {
        name: '服务评价',
        rules: 1,
      },
    }]
  },
  {
    path: '/order',
    name: 'order',
    component: Home,
    redirect: '/order/orderList',
    meta: {
      icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/homeMenu/order.png',
      name: '订单',
      title: '订单管理',
      rules: 1,
    },
    children: [
      {
        path: '/order/orderList',
        component: () => import('@/views/order/OrderList'),
        name: 'orderList',
        meta: {
          name: '商品订单',
          rules: 1,
        },
      },
      {
        path: '/order/salesRefund',
        component: () => import('@/views/order/SalesRefund'),
        name: 'salesRefund',
        meta: {
          name: '售后退款',
          rules: 1,
        },
      },
      {
        path: '/order/salesHandle/:id/:status/:page/:rows',
        component: () => import('@/views/order/SalesHandle'),
        name: 'salesHandle',
        meta: {
          name: '售后处理',
          relation: 'salesRefund',
          rules: 0,
        },
      },
      {
        path: '/order/orderInfo/:id/:page/:rows',
        component: () => import('@/views/order/OrderInfo'),
        name: 'orderInfo',
        meta: {
          name: '订单信息',
          relation: 'orderList',
          rules: 0,
        },
      },
      {
        path: '/order/serviceOrder',
        component: () => import('@/views/order/ServiceOrderList'),
        name: 'serviceOrder',
        meta: {
          name: '服务订单',
          rules: 1,
        },
      },
      {
        path: '/order/serviceOrderInfo',
        component: () => import('@/views/order/ServiceOrderInfo'),
        name: 'serviceOrderInfo',
        meta: {
          name: '订单详情',
          relation: 'serviceOrder',
          rules: 0,
        },
      },
    ],
  },
  {
    path: '/shop',
    name: 'shop',
    component: Home,
    redirect: '/shop/consignmentGoods',
    meta: {
      icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/homeMenu/order.png',
      name: '店铺',
      title: '店铺管理',
      rules: 1,
    },
    children: [{
      path: '/shop/consignmentGoods',
      name: 'consignmentGoods',
      component: () => import('@/views/shop/consignmentGoods'),
      meta: {
        name: '代销商品',
        rules: 1
      },
    }, {
      path: '/shop/consignmentService',
      name: 'consignmentService',
      component: () => import('@/views/shop/consignmentService'),
      meta: {
        name: '代销服务',
        rules: 1
      },
    }]
  },
 
  {
    path: '/store',
    name: 'store',
    component: Home,
    redirect: '/store/storeSet',
    meta: {
      icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/homeMenu/store.png',
      name: '门店',
      title: '门店管理',
      rules: 1,
    },
    children: [
      {
        path: '/store/storeSet',
        name: 'storeSet',
        component: () => import('@/views/store/Set'),
        meta: {
          name: '门店设置',
          rules: 1,
          form: 1
        },
      },
      {
        path: '/store/storeUsersList',
        name: 'storeUsersList',
        component: () => import('@/views/store/ClerkList'),
        meta: {
          name: '店员账号管理',
          rules: 1,
        },
      },
      {
        path: '/store/addStoreUsers',
        name: 'addStoreUsers',
        component: () => import('@/views/store/AddClerk'),
        meta: {
          name: '添加店员账号',
          rules: 1,
          form: 1
        },
      },
      {
        path: '/store/editClerk',
        name: 'editClerk',
        component: () => import('@/views/store/AddClerk'),
        meta: {
          name: '编辑店员账号',
          rules: 0,
          relation: 'storeUsersList',
          form: 1
        },
      },
      {
        path: '/store/storeRolesList',
        name: 'storeRolesList',
        component: () => import('@/views/store/RoleList'),
        meta: {
          name: '店员角色管理',
          rules: 1,
        },
      },
      {
        path: '/store/freightList',
        name: 'freightList',
        component: () => import('@/views/store/Freightlist'),
        meta: {
          name: '运费设置',
          rules: 1,
        },
      },
      {
        path: '/store/freight',
        name: 'freight',
        component: () => import('@/views/store/Freight'),
        meta: {
          name: '添加运费',
          relation: 'freightList',
          rules: 0,
          form: 1
        },
      },
      {
        path: '/store/freightEdit/:id/:page/:rows',
        name: 'freightEdit',
        component: () => import('@/views/store/Freight'),
        meta: {
          name: '编辑运费',
          relation: 'freightList',
          rules: 0,
          form: 1
        },
      },
      {
        path: '/store/sourceCenter',
        name: 'sourceCenter',
        component: () => import('@/views/store/sourceCenter/index00.vue'),
        meta: {
          name: '素材中心',
          rules: 1,
        },
      },
      {
        path: '/store/printSheet',
        name: 'printSheet',
        component: () => import('@/views/store/printSheet'),
        meta: {
          name: '收银账号',
          rules: 1,
        },
      },
    ],
  },
  {
    path: '/extension',
    name: 'extension',
    component: Home,
    redirect: '/extension/currentprice',
    meta: {
      icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/homeMenu/extension.png',
      name: '推广',
      title: '推广管理',
      rules: 1,
    },
    children: [
      {
        path: '/extension/couponList',
        name: 'couponList',
        component: () => import('@/views/extension/CouponList'),
        meta: {
          name: '优惠券列表',
          rules: 1,
        },
      },
      {
        path: '/extension/currentprice',
        name: 'extension-currentprice',
        redirect: '/extension/currentprice/list',
        component: () => import('@/views/extension/currentprice/index'),
        meta: {
          name: '时价管理',
          rules: 1,
          isOpenThirdMenu: 0,
          isShowThirdMemu: 1,
        },
        children:[{
          path: '/extension/currentprice/list',
          name: 'extension-currentprice-list',
          component: () => import('@/views/extension/currentprice/list'),
          meta: {
            name: '时价列表',
            rules: 1,
          }
        },{
          path: '/extension/currentprice/audit',
          name: 'extension-currentprice-audit',
          component: () => import('@/views/extension/currentprice/audit'),
          meta: {
            name: '时价审核',
            rules: 1,
          }
        },{
          path: '/extension/currentprice/order',
          name: 'extension-currentprice-order',
          component: () => import('@/views/extension/currentprice/order'),
          meta: {
            name: '时价订单',
            rules: 1,
          }
        }]
      },
      {
        path: '/extension/advertisement',
        name: 'extension-advertisement',
        redirect: '/extension/advertisement/list',
        component: () => import('@/views/extension/advertisement/index'),
        meta: {
          name: '广告管理',
          rules: 1,
          isOpenThirdMenu: 0,
          isShowThirdMemu: 1,
        },
        children: [{
          path: '/extension/advertisement/list',
          name: 'extension-advertisement-list',
          component: () => import('@/views/extension/advertisement/list'),
          meta: {
            name: '广告列表',
            rules: 1
          },
        }, {
          path: '/extension/advertisement/order',
          name: 'extension-advertisement-order',
          component: () => import('@/views/extension/advertisement/order'),
          meta: {
            name: '广告订单',
            rules: 1
          },
        }]
      },
    ],
  },
  {
    path: '/finance',
    name: 'finance',
    component: Home,
    redirect: '/finance/withdrawnList',
    meta: {
      icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/homeMenu/money.png',
      name: '财务',
      title: '财务管理',
      rules: 1,
    },
    children: [
      {
        path: '/finance/withdrawnList',
        name: 'withdrawnList',
        component: () => import('@/views/finance/FinancialReconciliation'),
        meta: {
          name: '财务对账',
          rules: 1,
        },
      },
      {
        path: '/finance/withdrawLog',
        name: 'withdrawLog',
        component: () => import('@/views/finance/WithdrawalRecord'),
        meta: {
          name: '提现记录',
          rules: 1,
        },
      },
      {
        path: '/finance/withdrawalrecordDetail',
        name: 'withdrawalrecordDetail',
        component: () => import('@/views/finance/WithdrawalrecordDetail'),
        meta: {
          name: '提现详情',
          relation: 'withdrawLog',
          rules: 0,
        },
      },
      {
        path: '/finance/account',
        name: 'account',
        component: () => import('@/views/finance/WithdrawalAccount'),
        meta: {
          name: '提现账号',
          rules: 1,
          form: 1
        },
      },
    ],
  },
  // 硬件
  {
    path: '/hardware',
    name: 'hardware',
    component: Home,
    redirect: '/hardware/printTicket',
    meta: {
      icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/homeMenu/hardware.png',
      name: '硬件',
      title: '硬件管理',
      rules: 1,
    },
    children: [
      {
        path: '/hardware/printTicket',
        name: 'printTicket',
        component: () => import('@/views/hardware/printTicket'),
        meta: {
          name: '打印机设置',
          rules: 1,
          form: 1,
        },
      },
      {
        path: '/hardware/scanCodeGun',
        name: 'scanCodeGun',
        component: () => import('@/views/hardware/scanCodeGun'),
        meta: {
          name: '扫描枪',
          rules: 1,
        },
      },
      {
        path: '/hardware/Pad',
        name: 'pad',
        component: () => import('@/views/hardware/Pad'),
        meta: {
          name: 'PAD',
          rules: 1,
        },
      },
    ],
  },
];
export default routerSurface;
