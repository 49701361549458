<template>
  <div class="layout">
    <div class="layout-menu">
      <div class="logo">
        <el-image :src="userInfo.staff_avatar || require('@/assets/user.png')"></el-image>
      </div>
      <el-menu class="el-menu-vertical-demo" @select="selectMenu" background-color="#010E27" text-color="#fff" active-text-color="#fff">
        <el-menu-item :index="index" v-for="(item, index) in routers" :key="index">
          <div :class="['item', activeIndex == index ? 'el-menu-item-active' : '']">
            <!-- <i :class="['iconfont', item.meta.icon]"></i> -->
            <img :src="item.meta.icon" style="margin-right: 8px" alt="" />
            <span>{{ item.meta.name }}</span>
          </div>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="child-menu" v-if="routers.length" ref="child_menu" :style="{ left: isOpen ? '120px' : '-35px' }">
      <div class="child-menu-title">
        {{ routers[activeIndex] && routers[activeIndex].meta.title }}
      </div>
      <div class="item" v-for="(item, index) in routers[activeIndex] && routers[activeIndex].children" :key="index">
        <div class="title" @click="selectChildMenu(item, index)">
          <span
            :style="{
              background: index == activeIndex1 && !item.meta.isShowThirdMemu ? '#3370ff' : '#fff',
            }"
          ></span>
          <a :style="{ color: index == activeIndex1 && !item.meta.isShowThirdMemu ? '#3370ff' : '' }">{{ item.meta.name }}</a>
          <i
            v-show="item.meta.isShowThirdMemu"
            :style="
              item.meta.isOpenThirdMenu
                ? {
                    transform: `rotateZ(90deg)`,
                  }
                : {
                    transform: `rotateZ(0deg)`,
                  }
            "
            class="el-icon-arrow-right"
          ></i>
        </div>
        <div ref="item_child_box" class="item-child-box">
          <div id="item-child" class="item-child" @click="selectThirdMemu(childItem, childIndex, index)" v-for="(childItem, childIndex) in item.children" :key="childIndex">
            <span :style="{ color: childItem.meta.selected ? '#3370ff' : '' }">{{ childItem.meta.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="menu-content">
      <div class="seize" ref="seize" :style="{ width: isOpen ? '135px' : '0' }"></div>
      <div class="view-content">
        <div class="view-title">
          <div v-if="!showEnd_time">
            {{ shwoPageName }}
          </div>
          <div v-else></div>
          <div class="userInfo">
            <div class="tips">
              <el-badge :value="msgCount" :hidden="msgCount == 0">
                <i class="iconfont icon--_tishi"></i>
              </el-badge>
            </div>
            <el-dropdown class="userName" @command="handleCommand">
              <span class="el-dropdown-link">
                <i class="iconfont icon-guanliyuan"></i>
                {{ userInfo.staff_account || 'admin' }}
                <i class="el-icon-caret-bottom"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="outLogin">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="router-view">
          <router-view ref="rightBot" @msgCountMethod="msgCountMethod" :key="$route.fullPath"></router-view>
          <div class="t_logo" v-show="!is_form">
            <el-image :src="require('@/assets/t_logo.png')"></el-image>
          </div>
        </div>
      </div>
    </div>
    <!-- 播放音频 -->
    <audio ref="audio" src="../assets/audio/neworderTZ.mp3"></audio>
  </div>
</template>

<script>
import { getDateformat } from '@/util/getDate';
export default {
  data() {
    return {
      isOpen: !1,
      activeIndex: 0,
      activeIndex1: 0,
      is_form: '',
      shwoPageName: '',
      socket: null,
      socketArr: [],
      path: 'wss://digitalizesocket.cnweisou.net?access_token=',
      audio: null,
      end_time: null,
      showEnd_time: !0,
      msgCount: 0,
      showSwitch: !1,
      siteList: [],
    };
  },
  watch: {
    $route: {
      handler(val) {
        this.shwoPageName = val.meta.name;
        this.is_form = val.meta.form;
        this.$store.state.utils.is_update = 0;
        if (val.path == '/index') {
          this.showEnd_time = !0;
          let timer = setInterval(() => {
            if (this.$refs.rightBot && this.$refs.rightBot.flag) {
              this.msgCountMethod();
              clearInterval(timer);
            }
          }, 10);
        } else {
          this.showEnd_time = !1;
        }
        if (val.path == '/extension/couponAdd') {
          this.activeIndex1 = 1;
        }
        if (val.path == '/freight' || val.path == '/customerService/addQuickReply' || val.path == '/customerService/addQADB') {
          this.activeIndex1 = -1;
        }
        if (this.routers.length) this.handleMenu();
        //  this.handleMenu();
      },
      immediate: true,
    },
  },
  created() {
    if (typeof WebSocket === 'undefined') {
      this.$message.error('您的浏览器不支持socket');
    }
    let token = localStorage.getItem('token');
    this.path += token;
    this.routers.map((item) => {
      if (item.path == '/') {
        this.socket = new WebSocket(this.path);
        this.socket.onopen = this.open;
      }
    });
    this.$store.dispatch('user/getUserInfo')
  },
  mounted() {
    if (this.socket) {
      this.audio = this.$refs.audio;
      this.socket.onmessage = this.getMessage;
    }
    this.end_time = getDateformat(Number(localStorage.getItem('uniac_endtime')));
    let a = document.createElement('div');
    a.id = 'download';
    document.body.appendChild(a);
    a.click();
    let aDom = document.getElementById('download');
    document.body.removeChild(aDom);
  },
  computed: {
    // 路由信息
    routers() {
       return this.$store.getters.menuLeft;
    },
    // 登录的用户信息
    userInfo() {
      return this.$store.getters.userInfo;
    },
    // 不显示的菜单
    relationMenu() {
      return this.$store.getters.relationMenu;
    },
  },
  methods: {
    handleCommand(val) {
      if (val == 'outLogin') {
        this.outLogin();
      }
    },
    // 开启websocket
    open() {
      console.log('开启web');
    },
    msgCountMethod(index) {
      if (index === undefined) {
        this.msgCount = this.$refs.rightBot.msgNotification.length;
      } else {
        this.msgCount = index;
      }
    },
    // websocket 监听消息
    getMessage(data) {
      console.log(JSON.parse(data.data));
      let res = JSON.parse(data.data);
      if (res.type != undefined) {
        let path1 = this.toDealMsg(res.type);
        this.$set(res, 'path', path1);
        this.socketArr.push(res);
        if (this.$route.path == '/index') {
          console.log(res);
          this.$refs.rightBot.msgNotification.push({ content: res.message, create_time: res.create_time, type: res.type });
          this.msgCountMethod();
        }
        if (res.type == 1) {
          this.audio.play();
          this.msgBox(res);
        }
      }
    },
    // 筛选去处理的跳转路径
    toDealMsg(index) {
      if (index == 6) {
        this.$message({
          message: '暂未开放客服模块',
          type: 'warning',
        });
        return '';
      }
      let path2 = '';
      switch (index) {
        case 1:
          path2 = '/order/orderList?tab_type=state_pay';
          break;
        case 2:
          path2 = '/order/salesRefund';
          break;
        case 3:
          path2 = `/store/storeManagement?id=${index.store_id}`;
          break;
        case 4:
          path2 = `/store/reflectAudit?id=${index.store_id}`;
          break;
        case 5:
          path2 = '/set/weixin?from=1';
          break;
        case 6:
          path2 = '';
          break;
        case 7:
          path2 = '/goods/GoodsEvaluate';
          break;
        case 8:
          path2 = '/user/userList';
          break;
        case 9:
          path2 = '/user/userList';
          break;
        case 10:
          path2 = '/user/userList';
          break;
        default:
          break;
      }
      return path2;
    },
    // 右侧弹出消息提示
    msgBox(res) {
      let that = this;
      const notify = this.$notify({
        duration: 3000,
        dangerouslyUseHTMLString: true,
        message: `<div class='msgBox' style='display:flex;justify-content:space-between;align-items: center;'><div><img style='width:40px;height:40px;border-radius:50%' src='${
          res.avatar
        }' /></div><div style=" max-width:270px; margin:0 24px 0 20px;display: flex;flex-direction: column;justify-content: space-around;"><div style='margin-bottom:5px'><b>【${
          res.type == 1 ? '新订单通知' : ''
        }】</b></div><div style='margin-left:4px;text-align:left;font-size:14px'>
        <p>用户[<span style='color:#5654F3'>${res.nickname.substring(0, 1)}***${res.nickname.substring(res.nickname.length - 1)}</span>]下了新的订单,请及时处理！</p>
        <p>订单号:${res.message.substring(14, 36)}</p>
        </div></div><div style='font-size:12px;flex-shrink: 0;text-align: center;width: 56px;height: 28px;line-height: 28px;color:#fff;background:#5654F3;cursor: pointer;' id='btnDeal' >处理<div></div>`,
      });
      // 给通知中的按钮注册事件
      notify.$el.querySelector('#btnDeal').onclick = () => {
        notify.close();
        that.$router.push({
          path: `${res.path}`,
        });
      };
    },
    // 处理菜单
    handleMenu() {
      let that = this;
      let routers = this.routers;
      routers.map((item, index) => {
        item.children.map((items, indexs) => {
          if (items.name == that.$route.name) {
            that.activeIndex = index;
            that.activeIndex1 = indexs;
            that.isShowSonMenu();
          }
          if (that.relationMenu.includes(that.$route.name)) {
            if (items.name == that.$route.meta.relation) {
              that.activeIndex = index;
              that.activeIndex1 = indexs;
              that.isShowSonMenu();
            }
          }
          //处理三级菜单选中状态
          if (items.meta.isShowThirdMemu && items.children.length > 0) {
            items.children.map((row, idx) => {
              if (row.name == that.$route.name || (this.relationMenu.includes(that.$route.name) && row.name == that.$route.meta.relation)) {
                that.activeIndex = index;
                that.activeIndex2 = idx;
                that.activeIndex1 = indexs;
                items.meta.isOpenThirdMenu = 1;
                items.meta.isClickMenu = 1;
                row.meta.selected = 1;
                setTimeout(() => {
                  that.$refs.item_child_box[indexs].style.height = (document.getElementById('item-child').clientHeight + 25) * items.children.length + 'px';
                }, 50);
                that.isShowSonMenu();
              }
            });
          }
        });
      });
      if (document.getElementById('item-child')) {
        if (routers[that.activeIndex].path != '/extension' && routers[that.activeIndex].path != '/user' && routers[that.activeIndex].path != '/shopManagement') {
          that.resetThirdMenuOpen();
        } else {
          that.resetThirdMenuSelected();
          if (that.activeIndex2 >= 0 && that.activeIndex2 != undefined && typeof that.activeIndex2 == 'number') {
            if (routers[that.activeIndex].children[that.activeIndex1].children) {
              routers[that.activeIndex].children[that.activeIndex1].children[that.activeIndex2].meta.selected = 1;
            }
          }
        }
      }
    },
    //重置三级菜单的选中状态
    resetThirdMenuSelected() {
      this.routers.map((item) => {
        item.children.map((item) => {
          if (item.children) {
            item.children.map((item) => (item.meta.selected = 0));
          }
        });
      });
    },
    //重置三级菜单折叠状态&选中状态
    resetThirdMenuOpen() {
      if (!this.isOpen) return;
      this.$refs.item_child_box.map((item) => {
        item.style.height = 0;
      });
      this.routers.map((item) => {
        item.children.map((item) => {
          item.meta.isOpenThirdMenu = 0;
        });
      });
      this.resetThirdMenuSelected();
    },
    //选择三级菜单
    selectThirdMemu(data, i, y) {
      if (i != this.activeIndex2 || y != this.activeIndex1 || this.$route.meta.relation == data.name) {
        this.$router.push(data.path);
        this.resetThirdMenuSelected();
        data.meta.selected = 1;
      } else {
        if (typeof this.activeIndex2 == 'string') {
          this.activeIndex2 = i;
          this.$router.push(data.path);
          this.resetThirdMenuSelected();
          data.meta.selected = 1;
        }
      }
    },
    // 选择子菜单
    selectChildMenu(data, i) {
      let utils = this.$store.state.utils;
      if (utils.is_update) {
        this.$confirm('未保存，是否离开？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          utils.is_update = 0;
          if (i != this.activeIndex1) {
            this.$router.push(data.path);
            this.$store.state.utils.is_record = !1;
          } else {
            if (data.path != this.$route.path && !this.$route.redirectedFrom) {
              this.$router.push(data.path);
            }
          }
        });
      } else {
        if (i != this.activeIndex1) {
          //是否有三级菜单需要展开
          if (data.meta.isShowThirdMemu) {
            if (data.meta.isOpenThirdMenu) {
              data.meta.isOpenThirdMenu = 0;
              this.$refs.item_child_box[i].style.height = 0;
            } else {
              data.meta.isOpenThirdMenu = 1;
              this.$refs.item_child_box[i].style.height = (document.getElementById('item-child').clientHeight + 25) * data.children.length + 'px';
            }
          } else {
            this.resetThirdMenuSelected();
            if (data.meta.target) {
              let routeUrl = this.$router.resolve({
                path: data.path,
              });
              window.open(routeUrl.href, data.meta.target);
            } else {
              this.$router.push(data.path);
            }
            this.$store.state.utils.is_record = !1;
          }
        } else {
          if (data.path != this.$route.path && !this.$route.redirectedFrom) {
            if (!data.meta.isShowThirdMemu) {
              this.$router.push(data.path);
            } else {
              if (data.meta.isOpenThirdMenu) {
                data.meta.isOpenThirdMenu = 0;
                this.$refs.item_child_box[i].style.height = 0;
              } else {
                data.meta.isOpenThirdMenu = 1;
                this.$refs.item_child_box[i].style.height = (document.getElementById('item-child').clientHeight + 25) * data.children.length + 'px';
              }
            }
          }
        }
      }
    },
    // 选择一级菜单
    selectMenu(i) {
      let data = this.routers[i];
      let utils = this.$store.state.utils;
      console.log(i, this.activeIndex, utils.is_update);
      if (utils.is_update) {
        this.$confirm('未保存，是否离开？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          utils.is_update = 0;
          if (i != this.activeIndex) {
            this.$router.push(data.path);
            this.activeIndex = i;
            this.activeIndex1 = 0;
            this.$store.state.utils.is_record = !1;
            this.resetThirdMenuOpen();
          } else {
            if (data.path != this.$route.path) {
              this.$router.push(data.path);
              this.resetThirdMenuOpen();
            }
          }
          this.isShowSonMenu();
        });
      } else {
        if (i != this.activeIndex) {
          this.$router.push(data.path);
          this.activeIndex = i;
          this.activeIndex1 = 0;
          this.$store.state.utils.is_record = !1;
          this.resetThirdMenuOpen();
          //判断二级菜单第一个是否有三级菜单，如果有就默认展开
          if (data.children[0].meta.isShowThirdMemu) {
            data.children[0].meta.isOpenThirdMenu = 1;
            data.children[0].children[0].meta.selected = 1;
            this.$nextTick(() => {
              this.$refs.item_child_box[0].style.height = (document.getElementById('item-child').clientHeight + 25) * data.children[0].children.length + 'px';
            });
          }
        }
        this.isShowSonMenu();
      }
    },
    // 判断该路由是否需要显示子菜单
    isShowSonMenu() {
      let noArr = ['/index', '/upRenew'];
      let res = noArr.find((item) => item === this.$route.path);
      if (res) {
        this.isOpen = !1;
      } else {
        this.isOpen = !0;
      }
    },
    //退出登录
    outLogin() {
      // this.socket.close = this.close;
      this.$store.dispatch('user/outLogin');
    },
    // 关闭websocket
    close() {
      console.log('socket服务已经关闭');
    },
  },
};
</script>

<style lang="less">
#switch {
  display: flex;
  align-items: center;
  .store_logo {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 50%;
  }
  span {
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .img {
    width: 15px;
    height: 15px;
    margin-left: 10px;
  }
}
.layout {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-left: 120px;
  position: relative;

  .layout-menu {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    height: 100%;
    width: 120px;
    background: #010e27;
    color: #fff;
    .logo {
      text-align: center;
      margin: 0 auto;
      margin: 16px auto;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      position: relative;
      color: #2e2e2e;

      .el-image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }
    .el-menu {
      border: none;
      .el-menu-item {
        height: 50px;
        line-height: 50px;
        width: 100%;
        padding: 0 !important;
        font-size: 15px;
        .item {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .iconfont {
          color: #fff;
          margin-right: 5px;
          font-size: 17px;
        }
      }
      .el-menu-item-active {
        background: #1467ff;
      }
    }
  }
  .child-menu {
    position: fixed;
    width: 135px;
    height: 100%;
    top: 0;
    background: #fff;
    transition: left 0.2s;
    text-align: center;
    overflow-x: auto;
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
    .child-menu-title {
      line-height: 66px;
      text-align: center;
      font-size: 15px;
      border-bottom: 1px solid rgb(230, 224, 224);
    }
    .item {
      margin-top: 30px;
      .title {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding-left: 10px;
        span {
          width: 3px;
          height: 3px;
          background: black;
          border-radius: 5px;
          margin-right: 3px;
          height: 16px;
        }
        a {
          font-size: 14px;
          text-decoration: none;
          position: relative;
        }
        i {
          font-size: 14px;
          color: #878787;
          margin-left: 2px;
          transition: all 0.3s;
        }
      }
      .item-child-box {
        transition: height 0.2s;
        height: 0;
        overflow: hidden;
      }
      .item-child {
        margin-top: 25px;
        font-size: 14px;
        text-align: left;
        padding-left: 25px;
        cursor: pointer;
      }
    }
  }
  .menu-content {
    width: 100%;
    background: #f8f8fa;
    height: 100%;
    display: flex;
    .seize {
      width: 0;
      height: 100%;
      transition: width 0.2s;
      flex-shrink: 0;
    }
    .view-content {
      flex: 1;
      width: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      position: relative;
      .view-title {
        height: 66px;
        background: #fff;
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px 0 15px;
        font-size: 14px;
        box-shadow: 0 1px 4px rgb(216, 206, 206);
        .userInfo {
          display: flex;
          .iconfont {
            font-size: 20px;
          }
          .tips {
            margin-right: 25px;
          }
          .userName {
            cursor: pointer;
          }
          .outLogin {
            cursor: pointer;
            margin-right: 20px;
          }
        }
      }
      .router-view {
        flex: 1;
        padding: 80px 15px 0 15px;
        overflow-y: auto;
        box-sizing: border-box;
        width: 100%;
        .t_logo {
          text-align: center;
          margin: 20px 0;
        }
      }
    }
  }
}
.el-notification {
  width: 440px !important;
  padding: 16px 24px !important;
  & > div {
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
    .el-notification__closeBtn {
      top: 8px !important;
      right: 8px !important;
    }
  }

  .el-notification__content {
    margin-top: 0px;
  }
}
</style>
